import { createResource, Suspense, Show, createMemo, For } from "solid-js";
import { getWrapper } from "~/lib/fetcher";
import CardShell from "./CardShell";
import * as helper from "~/utils/helper_program";
import {
  deliveryTrimester,
  getParkingsByTypologies,
} from "~/utils/helper_program";
import imgProxy from "~/utils/imgproxy";
import { useModalFormContext } from "~/contexts/ModalFormContext";

import type { ProgramWrapper } from "~/utils/program_wrapper";

import "./Card.css";
import { formatPrice, sanitizeString } from "~/utils/format";
import { useEventsContext } from "~/contexts/EventsContext";

export default function CardParking(props: {
  nid: number;
  title?: string;
  url?: string;
}) {
  const [wrapper] = createResource(() => props.nid, getWrapper);

  return (
    <>
      <Suspense fallback={<CardShell title={props.title} url={props.url} />}>
        <Show when={wrapper()}>
          <article
            class="node-program-parking-card"
            data-ga-zone="card"
            data-test={`card-parking-${wrapper()!.program.field_program_id}`}
          >
            <div class="visual">
              <picture>
                <img
                  src={imgProxy(
                    "/images/parkings-card-bg.jpg",
                    `size:375:200/resizing_type:fill`,
                  )}
                  alt=""
                  height="160"
                  width="327"
                />
              </picture>
              <h2>
                <address
                  itemprop="address"
                  itemscope
                  itemtype="https://schema.org/PostalAddress"
                >
                  <span class="postal-code">
                    <span itemprop="postalCode">
                      {wrapper()!.program.field_postal_code!.slice(0, 2)}
                    </span>
                    /
                  </span>{" "}
                  <span itemprop="addressLocality">
                    {wrapper()!.program.field_city}
                  </span>
                </address>
              </h2>
              <span class="street" data-test="street">
                {wrapper()!.program.field_street}
              </span>
              <Show
                when={
                  !helper.isPreview(wrapper()!) &&
                  !wrapper()!.program.field_date_delivery_hide &&
                  deliveryTrimester(wrapper()!)
                }
              >
                <span class="delivery" data-test="delivery">
                  Livraison à partir du{" "}
                  <span innerHTML={deliveryTrimester(wrapper()!)} />
                </span>
              </Show>
            </div>
            <div class="content-parking" data-test="typologies">
              <CardTypology wrapper={wrapper()!} />
            </div>
          </article>
        </Show>
      </Suspense>
    </>
  );
}

function CardTypology(props: { wrapper: ProgramWrapper }) {
  const [, { openModalForm, setModalFormHeadline }] = useModalFormContext();
  const [, { sendShowEvent }] = useEventsContext();

  const parkingsGroupedByTypologies = createMemo(() => {
    return getParkingsByTypologies(props.wrapper);
  });

  return (
    <>
      <div
        class="mask"
        classList={{
          disabled: parkingsGroupedByTypologies().length <= 3,
        }}
      >
        <For each={parkingsGroupedByTypologies()}>
          {(typology) => (
            <>
              <div
                class="typology"
                data-test={`typology-${sanitizeString(typology.typology.name)}`}
              >
                <div class="summary" data-test="summary">
                  <span class="name" data-test="parking-type">
                    {typology.typology.name}
                  </span>
                  <span class="price" data-test="price-smallest">
                    <small>à partir de</small>{" "}
                    <span itemprop="priceRange">
                      <Show
                        when={Number.isFinite(typology.getCheapestPrice())}
                        fallback="Prix nous consulter"
                      >
                        {formatPrice(typology.getCheapestPrice())}
                      </Show>
                    </span>
                  </span>
                </div>
                <div class="action">
                  <Show
                    when={typology.lots.length > 1}
                    fallback={
                      <span class="count last" data-test="count">
                        Dernière dispo.
                      </span>
                    }
                  >
                    <span class="count" data-test="count">
                      {typology.lots.length} dispo.
                    </span>
                  </Show>
                  <button
                    type="button"
                    class="btn"
                    onClick={(e) => {
                      sendShowEvent("parking", e);
                      openModalForm!("parking", {
                        wrapper: props.wrapper,
                        lot: typology.getFirstLotWithPrice(),
                      });
                      setModalFormHeadline!(
                        "Obtenir des informations sur un parking",
                      );
                    }}
                    data-test="cta-parking"
                  >
                    Nous contacter
                  </button>
                </div>
              </div>
            </>
          )}
        </For>
        <Show when={parkingsGroupedByTypologies().length > 3}>
          <div
            class="gradient"
            onClick={(e) => {
              e.currentTarget
                .closest(".content-parking")!
                .querySelectorAll(".mask")[0]
                .classList.toggle("disabled");
            }}
            data-test="expand-typologies"
          />
        </Show>
      </div>
    </>
  );
}
