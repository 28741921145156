import {
  For,
  Show,
  Suspense,
  createMemo,
  createSignal,
  createEffect,
  Switch,
  Match,
  onMount,
} from "solid-js";
import type {
  ItemReferenceWithGPS,
  ParkingCity,
  ParkingDepartment,
  ParkingsData,
} from "~/types/drupal_jsonapi";
import CardParking from "../Cards/CardParking";
import { createStore } from "solid-js/store";
import { clientOnly } from "@solidjs/start";
import { ITEMS_PER_PAGE } from "~/utils/constants";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { useWindowSize } from "@solid-primitives/resize-observer";
import { triggerResizeEvent } from "~/utils/map_utils";
import ParkingsRelatedLandings from "~/components/Parkings/Components/ParkingsRelated";
import ParkingsHeading from "~/components/Parkings/Components/ParkingsHeading";
import ParkingsSEOContent from "~/components/Parkings/Components/ParkingsSEOContent";

import IconKeyboardArrowLeft from "~/img/icons/keyboard_arrow_left.svg";
import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";

import "~/components/shared/SearchResults.css";
import "./ParkingsPage.css";
import IconExpandMore from "~/img/icons/expand_more.svg";
import IconClose from "~/img/icons/close.svg";
import { useParams } from "@solidjs/router";
import { urlRs } from "~/utils/url";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import GtmVars from "../shared/Trackers/GtmVars";

const ParkingsMap = clientOnly(() => import("./ParkingsMap"));

type ParkingStore = {
  departmentsList: ParkingDepartment[];
  citiesList: ParkingCity[];

  currentDepartment: ParkingDepartment | undefined;
  currentCity: ParkingCity | undefined;

  markers: ItemReferenceWithGPS[];
  slices: ItemReferenceWithGPS[][];
  displayedPrograms: ItemReferenceWithGPS[];

  currentPage: number;
  showMoreButtonLabel: string | undefined;
};

const [store, setStore] = createStore<ParkingStore>({
  departmentsList: [],
  citiesList: [],

  currentDepartment: undefined,
  currentCity: undefined,

  markers: [],
  slices: [],
  displayedPrograms: [],

  currentPage: 0,
  showMoreButtonLabel: undefined,
});

const [viewList, setViewList] = createSignal(true);
const [viewMixed, setViewMixed] = createSignal(false);
const [viewMap, setViewMap] = createSignal(false);

export default function ParkingsPage(props: ParkingsData) {
  const [viewportSizeProvider] = useViewportSizeContext();
  const size = useWindowSize();

  const attachExternalLinksBehavior = function () {
    for (let c = document.getElementsByTagName("a"), a = 0; a < c.length; a++) {
      const b = c[a];
      const isExt =
        b.getAttribute("href") &&
        (b.protocol === "https:" || b.protocol === "http") &&
        b.hostname !== location.hostname;

      const isAltarea = b.textContent!.toLowerCase().includes("altarea");

      if (isExt || isAltarea) {
        b.target = "_blank";
        b.classList.add("ext");
        b.setAttribute("rel", "noopener noreferrer nofollow");
      }
    }
  };

  onMount(() => attachExternalLinksBehavior());

  createEffect(() => {
    // On load, we set the proper layout based on resolution.
    // We don't want small devices to have tha map loaded by default.
    if (size.width > 768) {
      setViewList(false);
      setViewMixed(true);
    }
  });

  createEffect(() => {
    viewList();
    viewMap();
    viewMixed();

    setTimeout(() => {
      triggerResizeEvent();
    }, 400);
  });

  setStore("slices", () => {
    if (props.programs) {
      return Array.from(
        {
          length: Math.ceil(props.programs.length / ITEMS_PER_PAGE),
        },
        (_, i) =>
          props.programs.slice(
            i * ITEMS_PER_PAGE,
            i * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
          ),
      );
    } else {
      return [[]];
    }
  });

  setStore("displayedPrograms", store.slices[0]);

  function addNextSlice() {
    setStore("displayedPrograms", [
      ...store.displayedPrograms,
      ...store.slices[store.currentPage],
    ]);
  }

  const gtmValues = () => {
    return {
      "all.pageType": "contenu",
      "all.mainCategory": "investir",
      "all.category": "Parkings",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "parkings",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <div
        class="search-results-container"
        classList={{
          liste: viewList(),
          mixte: viewMixed(),
          carte: viewMap(),
        }}
      >
        {/* Map */}
        <div class="map" data-test="map">
          <Show when={viewMap() || viewMixed()}>
            <Suspense>
              <ParkingsMap programs={props.programs} />
            </Suspense>
            <ParkingsMapArrow />
          </Show>
        </div>

        <section class="search-results parkings-results" data-test="results">
          {/* Arrows */}
          <ParkingsArrows />
          <div class="content-part">
            {/* Switch */}
            <Show when={viewportSizeProvider.viewPortIsMoreThan768}>
              <ParkingsSwitch />
            </Show>

            {/* Heading */}
            <ParkingsHeading />

            {/* Filters */}
            <Filters departments={props.departments} cities={props.cities} />

            {/* Results */}
            <section
              class="parkings-list"
              data-ga-zone="list"
              data-test="parkings"
            >
              <For each={store.displayedPrograms}>
                {(program) => (
                  <CardParking
                    nid={program.id}
                    title={program.title}
                    url={program.url}
                  />
                )}
              </For>
              <Show when={store.currentPage < store.slices.length - 1}>
                <div class="dynamic-pager">
                  <button
                    class="btn"
                    data-test="results-next"
                    onClick={() => {
                      setStore("currentPage", store.currentPage + 1);
                      addNextSlice();
                    }}
                  >
                    Voir la suite
                  </button>
                </div>
              </Show>
            </section>

            {/* Related Landings */}
            <Show
              when={
                props.landings.filter((landing) => landing.published).length > 0
              }
            >
              <ParkingsRelatedLandings landings={props.landings} />
            </Show>

            {/* Seo Content */}
            <ParkingsSEOContent />
          </div>

          <div id="footer-alternate" />
        </section>
      </div>
    </>
  );
}

function ParkingsMapArrow() {
  return (
    <>
      <div class="arrow">
        <button
          type="button"
          aria-label="Mixte"
          class="btn btn-icon"
          onClick={() => {
            setViewList(false);
            setViewMixed(true);
            setViewMap(false);
          }}
          data-test="btn-expand-mixed"
        >
          <i aria-hidden="true" class="cog-icon">
            <IconKeyboardArrowLeft />
          </i>
        </button>
      </div>
    </>
  );
}

function ParkingsArrows() {
  const [viewportSizeProvider] = useViewportSizeContext();

  return (
    <>
      <div class="arrows" data-test="arrows">
        <button
          type="button"
          aria-label="Liste"
          class="btn btn-icon"
          onClick={() => {
            setViewList(true);
            setViewMixed(false);
            setViewMap(false);
          }}
          data-test="btn-expand-results"
        >
          <i aria-hidden="true" class="cog-icon">
            <IconKeyboardArrowLeft />
          </i>
        </button>{" "}
        <Show when={!viewportSizeProvider.viewPortIsMoreThan768}>
          <button
            type="button"
            class="btn"
            onClick={() => {
              setViewList(false);
              setViewMixed(false);
              setViewMap(true);
            }}
            data-test="btn-expand-map"
          >
            Carte
            <i aria-hidden="true" class="cog-icon">
              <IconKeyboardArrowRight />
            </i>
          </button>
        </Show>
        <Show when={viewportSizeProvider.viewPortIsMoreThan768}>
          <Show
            when={viewMixed()}
            fallback={
              <button
                type="button"
                aria-label="Mixte"
                class="btn btn-icon"
                onClick={() => {
                  setViewList(false);
                  setViewMixed(true);
                  setViewMap(false);
                }}
                data-test="btn-expand-mixed"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconKeyboardArrowRight />
                </i>
              </button>
            }
          >
            <button
              type="button"
              aria-label="Carte"
              class="btn btn-icon"
              onClick={() => {
                setViewList(false);
                setViewMixed(false);
                setViewMap(true);
              }}
              data-test="btn-expand-map"
            >
              <i aria-hidden="true" class="cog-icon">
                <IconKeyboardArrowRight />
              </i>
            </button>
          </Show>
        </Show>
      </div>
    </>
  );
}

function ParkingsSwitch() {
  return (
    <>
      <div class="switch" data-test="links">
        <button
          type="button"
          classList={{ active: viewList() }}
          onClick={() => {
            setViewList(true);
            setViewMixed(false);
            setViewMap(false);
          }}
          data-test="btn-expand-results"
        >
          Liste
        </button>{" "}
        <button
          type="button"
          classList={{ active: viewMixed() }}
          onClick={() => {
            setViewList(false);
            setViewMixed(true);
            setViewMap(false);
          }}
          data-test="btn-expand-mixed"
        >
          Mixte
        </button>{" "}
        <button
          type="button"
          classList={{ active: viewMap() }}
          onClick={() => {
            setViewList(false);
            setViewMixed(false);
            setViewMap(true);
          }}
          data-test="btn-expand-map"
        >
          Carte
        </button>
      </div>
    </>
  );
}

function Filters(props: {
  departments: ParkingDepartment[];
  cities: ParkingCity[];
}) {
  const cities = createMemo(() => {
    return props.cities.filter((c) =>
      store.currentDepartment
        ? store.currentDepartment.name === c.parent
        : props.departments.map((d) => d.name).includes(c.parent),
    );
  });

  const settings = useDrupalSettingsContext();

  function gotoSelection() {
    if (store.currentDepartment && store.currentCity) {
      window.location.href = urlRs(
        "parkings",
        `/investir/parkings/${store.currentDepartment.slug}/${store.currentCity.name}/`,
        settings,
      );
    } else if (store.currentDepartment) {
      window.location.href = urlRs(
        "parkings",
        `/investir/parkings/${store.currentDepartment.slug}/`,
        settings,
      );
    } else {
      window.location.href = urlRs("parkings", `/investir/parkings/`, settings);
    }
  }

  onMount(() => {
    const params = useParams();
    params.department
      ? setStore(
          "currentDepartment",
          props.departments.find((el) => el.slug === params.department),
        )
      : undefined;

    params.city
      ? setStore(
          "currentCity",
          props.cities.find((el) => el.name === params.city),
        )
      : undefined;
  });

  return (
    <>
      <div class="parkings-filters-form" data-test="filters">
        <FormInputSelectField
          dataTest="department"
          label="Département"
          items={props.departments}
        />
        <FormInputSelectField
          dataTest="city"
          label="Ville"
          items={cities()}
          cityField
          departments={props.departments}
        />
        <button
          type="button"
          class="btn form-submit"
          onClick={() => {
            gotoSelection();
          }}
          data-test="btn-filter"
        >
          Filtrer
        </button>
      </div>
    </>
  );
}

function FormInputSelectField(props: {
  dataTest: string;
  label: string;
  items: ParkingDepartment[] | ParkingCity[];
  cityField?: boolean;
  departments?: ParkingDepartment[];
}) {
  const [menuDialogIsActive, setMenuDialogIsActive] = createSignal(false);

  const [inputIsFocus, setInputIsFocus] = createSignal(false);

  return (
    <>
      <div
        class="form-control is-clearable"
        classList={{
          "is-focus": inputIsFocus(),
          "has-content":
            (props.cityField && store.currentCity !== undefined) ||
            (!props.cityField && store.currentDepartment !== undefined),
        }}
        data-test={props.dataTest}
      >
        <div class="form-slot form-select">
          <label for={`${props.dataTest}-select`}>{props.label}</label>
          <input
            id={`${props.dataTest}-select`}
            name="visit_select"
            type="text"
            value={
              props.cityField
                ? store.currentCity?.name || ""
                : store.currentDepartment?.name || ""
            }
            readonly
            onFocusIn={() => {
              setInputIsFocus(true);
              setMenuDialogIsActive(true);
            }}
            onBlur={() => {
              setInputIsFocus(false);
            }}
          />
          <Switch>
            <Match when={props.cityField && store.currentCity}>
              <button
                type="button"
                onClick={() => {
                  setStore("currentCity", undefined);
                }}
              >
                <IconClose />
              </button>
            </Match>
            <Match when={!props.cityField && store.currentDepartment}>
              <button
                type="button"
                onClick={() => {
                  setStore("currentDepartment", undefined);
                  setStore("currentCity", undefined);
                }}
              >
                <IconClose />
              </button>
            </Match>
          </Switch>
          <i aria-hidden="true" class="cog-icon">
            <IconExpandMore />
          </i>
          <div class="menu-dialog" classList={{ active: menuDialogIsActive() }}>
            <div
              class="dialog-overlay"
              onClick={() => setMenuDialogIsActive(false)}
            />
            <ul class="list">
              <For each={props.items}>
                {(item) => (
                  <>
                    <Show
                      fallback={
                        <li
                          onClick={() => {
                            setMenuDialogIsActive(false);
                            setStore("currentDepartment", item);
                            setStore("currentCity", undefined);
                          }}
                          classList={{
                            active: item.name === store.currentDepartment?.name,
                          }}
                        >
                          {item.name}
                        </li>
                      }
                      when={props.cityField}
                    >
                      <li
                        onClick={() => {
                          setMenuDialogIsActive(false);
                          const dep = props.departments!.find(
                            (el: ParkingDepartment) =>
                              "parent" in item && el.name === item.parent,
                          );
                          setStore("currentDepartment", dep);
                          setStore("currentCity", item);
                        }}
                        classList={{
                          active: item.name === store.currentCity?.name,
                        }}
                      >
                        <Show
                          fallback={store.currentDepartment?.name}
                          when={props.cityField}
                        >
                          {item.name}
                        </Show>
                      </li>
                    </Show>
                  </>
                )}
              </For>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
