import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { createMemo, createSignal, For, Show } from "solid-js";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";

import type { LandingElement } from "~/types/drupal_jsonapi";

import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";
import IconKeyboardArrowLeft from "~/img/icons/keyboard_arrow_left.svg";

import "~/components/shared/RelatedContentComponent.css";
import "~/components/Cards/CardRelated.css";
import "./ParkingsRelated.css";

export default function ParkingsRelatedLandings(props: {
  landings: LandingElement[];
}) {
  const [viewportSizeProvider] = useViewportSizeContext();

  const publishedLandings = createMemo(() => {
    return props.landings.filter((landing) => landing.published);
  });

  const [currentSlideIndex, setCurrentSlideIndex] = createSignal(1);

  function goNext() {
    if (viewportSizeProvider.viewPortIsLessThan768) {
      if (currentSlideIndex() + 1 <= publishedLandings().length) {
        setCurrentSlideIndex(currentSlideIndex() + 1);
      }
    } else {
      if (
        currentSlideIndex() + 1 <=
        Math.ceil(publishedLandings().length / 3)
      ) {
        setCurrentSlideIndex(currentSlideIndex() + 1);
      }
    }
  }

  function goPrevious() {
    if (currentSlideIndex() - 1 >= 1) {
      setCurrentSlideIndex(currentSlideIndex() - 1);
    }
  }

  return (
    <>
      <section class="related" data-test="related-contents">
        <div class="inner">
          <h2 class="related-title">Sur le même sujet</h2>
          <div class="related-cards">
            <div class="related-slider">
              <div
                class="slides"
                style={{
                  "margin-left": -(currentSlideIndex() - 1) * 100 + "%",
                }}
              >
                <For each={publishedLandings()}>
                  {(landing) => (
                    <article data-test="card-related" class="node-card-related">
                      <picture>
                        <img
                          src={imgProxy(
                            landing.image
                              ? landing.image
                              : "/images/default-related-visual.png",
                            `size:327:140`,
                          )}
                          alt=""
                          height="80"
                          width="188"
                        />
                      </picture>
                      <div class="content-related">
                        <h3>{landing.title}</h3>
                        <Show when={landing.catchline}>
                          <p data-test="catchline">
                            {landing.catchline.slice(0, 70)}...
                          </p>
                        </Show>
                        <a
                          href={urlRs("landings", landing.url)}
                          title={`Lire la suite de ${landing.title}`}
                        >
                          <i aria-hidden="true" class="cog-icon">
                            <IconKeyboardArrowRight />
                          </i>
                          <span class="legend">Lire la suite</span>
                        </a>
                      </div>
                    </article>
                  )}
                </For>
              </div>
            </div>
            <div
              class="related-nav"
              data-desktop-slides={Math.ceil(publishedLandings().length / 3)}
              data-mobile-slides={publishedLandings().length}
            >
              <button
                onClick={() => goPrevious()}
                class="arrow-left"
                data-test="btn-previous"
                aria-label="Précédent"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconKeyboardArrowLeft />
                </i>
              </button>
              <button
                onClick={() => goNext()}
                class="arrow-right"
                data-test="btn-next"
                aria-label="Suivant"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconKeyboardArrowRight />
                </i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
