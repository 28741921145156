import imgProxy from "~/utils/imgproxy";

import "./ParkingsHeading.css";

export default function ParkingsHeading() {
  return (
    <>
      <section class="heading" data-test="header">
        <picture>
          <source
            media="(min-width: 769px)"
            srcset={imgProxy(
              "/images/parkings-bg.jpg",
              `size:1212:866/resizing_type:fill`,
            )}
          />
          <source
            media="(max-width: 768px)"
            srcset={imgProxy(
              "/images/parkings-bg.jpg",
              `size:768:549/resizing_type:fill`,
            )}
          />
          <img
            src={imgProxy(
              "/images/parkings-bg.jpg",
              `size:375:240/resizing_type:fill`,
            )}
            alt=""
            height="240"
            width="375"
          />
        </picture>
        <h1>Notre sélection de parkings dans toute la France pour investir</h1>
      </section>
    </>
  );
}
