import { createSignal } from "solid-js";

import IconCustomPercent from "~/img/icons/custom/percent.svg";
import IconCustomMoney from "~/img/icons/custom/money.svg";
import IconCustomHouse from "~/img/icons/custom/house.svg";
import IconExpandMore from "~/img/icons/expand_more.svg";

import "./ParkingsSEOContent.css";

export default function ParkingsSEOContent() {
  const [isExpanded, setIsExpanded] = createSignal(false);
  return (
    <>
      <section class="seo-content" data-test="block-seo">
        <div class="inner">
          <div data-test="header">
            <h2 class="main">Pourquoi investir dans un parking neuf&nbsp;?</h2>
          </div>

          <div class="invest-items">
            <div class="invest-item" data-test="card-objective">
              <div class="svg-icon">
                <IconCustomPercent />
              </div>
              <h3>Un rendement attractif</h3>
              <p>
                Profitez d’un rendement potentiel de <strong>4 à 8%</strong>{" "}
                <sup>(1)</sup> grâce à une forte demande locative et des coûts
                d’entretien réduits.
              </p>
            </div>
            <div class="invest-item" data-test="card-objective">
              <div class="svg-icon">
                <IconCustomMoney />
              </div>
              <h3>Une accessibilité financière</h3>
              <p>
                Accédez au marché immobilier grâce à des{" "}
                <strong>prix d’achat abordables</strong>. Une alternative
                intéressante à l’épargne traditionnelle.
              </p>
            </div>
            <div class="invest-item" data-test="card-objective">
              <div class="svg-icon">
                <IconCustomHouse />
              </div>
              <h3>Un investissement serein</h3>
              <p>
                Les parkings neufs exigent <strong>peu d’entretien</strong> et
                vous offrent une tranquillité d’esprit grâce à leur durabilité
                et à une demande constante.
              </p>
            </div>
          </div>

          <div class="invest-example" data-test="example">
            <h4>Exemple d’investissement</h4>
            <p class="example-block">
              <span class="price">
                Prix d’achat :<strong>15 000 €</strong>
              </span>
              <span class="price">
                Loyer mensuel :<strong>70 €</strong>
              </span>
              <span class="calculation">
                Revenu mensuel x 12 mois / Coût d’achat du parking
                <br />
                (70 € x 12) / 15 000 € = 840 € / 15 000 € = 0,056
              </span>
              <span class="result">
                Rendement annuel brut
                <strong>5,6%*</strong>
                <span class="legend">*avant déduction des charges</span>
              </span>
            </p>
          </div>

          <div
            class="seo-text"
            classList={{ expanded: isExpanded() }}
            data-test="seo-text"
          >
            <div class="inner">
              <div class="text">
                <p class="catchline">
                  Les places de stationnement se font de plus en plus rares en
                  ville. Cela vient d’une volonté forte des pouvoirs publics de
                  mener un ensemble de mesures visant à réduire l’utilisation
                  des véhicules motorisés afin d’atteindre les objectifs
                  écologiques et environnementaux. Cela engendre donc une baisse
                  de l’offre de stationnement, et par conséquent une
                  augmentation des prix. Il peut donc s’avérer particulièrement
                  intéressant d’investir dans un parking lorsque celui-ci est
                  bien placé. Pour un usage personnel ou pour réaliser un
                  investissement locatif, ou encore simplement pour renforcer
                  votre patrimoine, Cogedim vous propose une sélection de
                  parkings idéalement situés.
                </p>
                <h2>Cogedim : acteur clé de vos projets immobiliers</h2>
                <p>
                  Investir dans l’immobilier demande un minimum de connaissance
                  pour réussir ses placements. Cela requiert, en effet, de
                  maîtriser certaines notions pour ne pas avoir de surprises
                  après engagement. Rassurez-vous, si vous ne possédez pas
                  suffisamment de connaissances en la matière, nos conseillers
                  Cogedim se tiennent à votre disposition pour décrypter
                  certains éléments et vous guider tout au long de votre projet.
                </p>
                <h3>Une facilité de financement</h3>
                <p>
                  Le financement peut constituer un frein dans la réalisation de
                  votre investissement. Avec notre partenaire Altarea Solution
                  Financement, vous avez la garantie de bénéficier de la
                  solution de financement la plus avantageuse et la plus adaptée
                  à votre situation personnelle. D’ailleurs, Cogedim vous offre
                  la possibilité de profiter d’une{" "}
                  <strong>
                    étude personnalisée gratuite et sans engagement
                  </strong>{" "}
                  qui vous permettra d’affiner votre recherche.
                </p>
                <h3>Un service client hors du commun</h3>
                <p>
                  Votre satisfaction est l’une de nos priorités. C’est pour cela
                  que nous mettons à votre disposition un interlocuteur unique
                  qui vous apportera un accompagnement personnalisé. La maîtrise
                  de votre projet lui permettra d’identifier facilement vos
                  besoins, de vous prodiguer des conseils sur-mesure et de vous
                  proposer des biens qui satisferont vos attentes.
                </p>
                <h2>Pourquoi investir dans une place de parking&nbsp;?</h2>
                <p>De nombreux avantages considérables pour l’investisseur.</p>
                <h3>
                  La possibilité de constituer et de diversifier son patrimoine
                  immobilier
                </h3>
                <p>
                  Constituer son <strong>patrimoine</strong> ne se fait pas en
                  une seule journée. Cela requiert de la patience et de
                  l’investissement de votre part. Investir dans un{" "}
                  <strong>parking</strong>, un <strong>garage</strong> ou un{" "}
                  <strong>box</strong> permet de s’initier au parcours d’achat
                  immobilier : offre, compromis, signature notaire, crédit,
                  location, impôts etc.
                </p>
                <p>
                  Posséder un parking c’est aussi enrichir et diversifier son
                  patrimoine. En effet il s’agit de biens qui prennent
                  rapidement de la valeur et qui sont faciles à transmettre. De
                  plus, très facile à louer, ils constituent de bons compléments
                  de revenus.
                </p>
                <p>
                  Cogedim vous propose, dans nos nouvelles résidences, des{" "}
                  <strong>stationnements en sous-sol</strong> sécurisés et
                  idéalement situés. Si vous recherchez un parking ou un garage
                  dans les <strong>métropoles françaises</strong> pour votre
                  usage personnel ou pour une location, profitez de nos parkings
                  à proximité des transports en commun et des bassins d’emplois.
                </p>
                <h3>Anticiper et préparer sa retraite</h3>
                <p>
                  Réaliser un <strong>placement immobilier</strong> pour une
                  place de stationnement peut vous aider à préparer et à
                  démarrer votre nouvelle aventure après une longue carrière de
                  dur labeur. Votre pension ne pourra couvrir qu’une partie de
                  vos charges, il est donc primordial de multiplier les
                  compléments de revenus et cela se prépare bien avant votre
                  retraite.
                </p>
                <h3>La souplesse du régime locatif</h3>
                <p>
                  Le <strong>régime locatif</strong> des garages et parkings est
                  différent de celui d’un logement traditionnel. En effet, on
                  observe une certaine souplesse quant à la fixation du prix du
                  loyer. Si vous êtes donc propriétaire de parkings, vous êtes
                  libre de déterminer le prix de location de votre place. Ici,
                  le bail peut être conclu à l’oral ou à l’écrit dans un
                  contrat. Toutefois, il est conseillé de respecter quelques
                  critères et d’adopter un tarif compétitif qui s’aligne à celui
                  proposé par d’autres particuliers. Cette astuce permettra de
                  sécuriser et d’assurer l’occupation d’une place de parking par
                  un locataire tout au long de l’année.
                </p>
                <h3>Un entretien facile et peu coûteux</h3>
                <p>
                  Investir dans un parking n’implique pas un entretien coûteux
                  étant donné que les travaux sont quasi inexistants. Son
                  aménagement ne nécessite pas de gros travaux ni des charges
                  importantes comme pour un logement, car l’obtention d’un
                  espace est suffisante. Si vous devez lui donner un coup de
                  jeune, il suffit d’appliquer des couches de peinture de
                  qualité pour donner un nouvel aspect et rafraîchir
                  régulièrement les murs. Assurez-vous de proposer à votre
                  locataire un emplacement de stationnement sain et propre.
                </p>
                <h3>Un investissement des plus rentables</h3>
                <p>
                  Acheter un place de parking nécessite un budget beaucoup plus
                  faible qu’un logement. Pour autant, la rentabilité du bien
                  n’est pas forcément plus faible. Avec un budget limité, vous
                  aurez certainement plus de chance d’obtenir le financement
                  d’un parking, qui s’étalera sur une période plus courte
                  pendant laquelle les loyers perçus couvriront largement le
                  montant de vos mensualités. En fonction de l’emplacement, vous
                  pourrez atteindre un{" "}
                  <strong>taux de rentabilité potentiel de 4 à 8%</strong>{" "}
                  <sup>(1)</sup>&nbsp;!
                </p>
                <h2>Comment investir dans un parking&nbsp;?</h2>
                <p>
                  Bien qu’investir dans une place de parking ou un garage est
                  plus facile et moins coûteux, il est toujours nécessaire
                  d’appliquer certains conseils en suivant quelques principes.
                  Voici donc les caractéristiques que vous devez retenir avant
                  de vous lancer dans votre projet d’acquisition de parking ou
                  box de garage.
                </p>
                <h3>Le choix de son emplacement</h3>
                <p>
                  Le choix de l’emplacement est le point le plus important à
                  considérer en investissant dans un parking, car cela a une
                  incidence évidente sur la demande locative. Il faut absolument
                  se positionner sur les localités les moins fournies en terme
                  de stationnement sur voirie ou investir dans un quartier
                  d’affaires afin de viser une clientèle professionnelle. Vous
                  serez ainsi sûr de trouver un locataire sans difficulté.
                </p>
                <p>
                  Il faut toutefois être stratégique dans cette démarche. Vous
                  devez impérativement réaliser une{" "}
                  <strong>rapide étude concurrentielle</strong> afin de
                  déterminer le montant de votre loyer. Pour cela il vous
                  suffira de rechercher les places de stationnements disponibles
                  dans la zone convoitée.
                </p>
                <h3>La sélection du type d’emplacement</h3>
                <p>
                  Un certain nombre de choix s’offrent à vous lorsqu’il est
                  question d’investir dans ce type de bien. Vous pouvez choisir
                  entre le box de garage et le parking à ciel ouvert ou couvert.
                  Aujourd’hui, il est important de savoir que les box sont les
                  plus demandés sur le marché, car ils offrent plus de sécurité
                  et permettent aux locataires de bénéficier d’offres
                  d’assurances moins coûteuses. Cependant, leur prix de vente
                  est 20 à 30 % plus cher qu’une place de parking en plein air.
                  Cette dernière peut par contre s’avérer plus rentable
                  lorsqu’elle est à l’intérieur d’une résidence Cogedim
                  sécurisée.
                </p>
                <p>
                  En investissant dans un box, vous devez vous assurer que sa
                  dimension permet de garer au moins un véhicule aussi large
                  qu’un 4x4 ou un monospace. Pour en avoir le cœur net n’hésitez
                  pas à faire le test en garant votre propre voiture dans ce
                  box.
                </p>
                <h3>Maîtriser son budget</h3>
                <p>
                  Le but de tout investissement est d’être rentable. Avant de
                  conclure votre achat, nous vous conseillons de prendre le
                  temps de calculer votre plan de financement et évaluer à
                  quelques centimes près le <strong>rendement</strong> que vous
                  allez générer. Vous devez ainsi considérer les charges de
                  copropriété s’il y en a, la taxe foncière, les frais de
                  gestion incluant les quittances par exemple, ainsi que la
                  prime d’assurance et les frais de notaire. Pour ce faire,
                  contactez votre conseiller Cogedim qui vous indiquera toutes
                  les charges à prendre en compte dans votre calcul.
                </p>
                <p>
                  Vous devez appliquer la formule mathématique suivante, afin de
                  connaître le rendement de votre investissement&nbsp;:{" "}
                  <strong>
                    (loyers annuels – charges annuelles) / prix total d’achat du
                    bien
                  </strong>
                  .
                </p>
                <h3>Obtention d’un crédit</h3>
                <p>
                  Même si vous disposez de suffisamment de liquidité pour
                  financer votre projet, il est fortement recommandé de faire
                  recours à un prêt. Cela vous évite de placer toutes vos
                  économies dans l’acquisition d’un parking ou d’un box. De
                  plus, un emprunt vous offrira la possibilité de faire
                  plusieurs acquisitions de parkings simultanément.
                </p>
                <p>
                  Afin de mettre toutes les chances de votre côté, nous vous
                  conseillons de donner des explications claires et détaillées
                  sur votre projet à votre conseiller bancaire. Vous pourrez en
                  tirer un avantage supplémentaire : celui d’obtenir un taux
                  d’intérêt préférentiel.
                </p>
                <p>
                  Plusieurs types de <strong>crédits immobiliers</strong> sont
                  disponibles. Il y a notamment le{" "}
                  <strong>crédit in fine</strong> qui doit être remboursé en une
                  seule fois. Nous pouvons également mentionner le{" "}
                  <strong>crédit amortissable</strong>. Ici, le remboursement
                  doit se faire chaque mois. Bien évidemment, les taux varient
                  en fonction du crédit. Certains emprunts sont à taux fixe,
                  c’est-à-dire que le taux d’intérêt est invariable durant toute
                  la période de votre crédit. D’autres sont à taux variables.
                </p>
                <p>
                  Afin de prendre tout cela en considération, il est préférable
                  de bénéficier de l’accompagnement de votre conseiller Cogedim.
                  Il saura vous conseiller sur la meilleure méthode de
                  financement qui convient à votre situation financière ainsi
                  qu’à vos besoins. En décidant de réaliser seul votre projet,
                  vous risquez d’oublier certains points ou de commettre des
                  erreurs qui peuvent vous coûter cher à l’avenir.
                </p>
                <h2>FAQ</h2>
                <div
                  itemscope
                  itemprop="mainEntity"
                  itemtype="https://schema.org/Question"
                >
                  <h3 itemprop="name">
                    Dans quelle région faut-il investir dans un parking&nbsp;?
                  </h3>
                  <div
                    itemscope
                    itemprop="acceptedAnswer"
                    itemtype="https://schema.org/Answer"
                  >
                    <div itemprop="text" class="faq-answer">
                      <p>
                        Il est préférable d’investir dans un parking dans les
                        régions comptant plus de 50&nbsp;000 habitants et où le
                        trafic est important. Privilégiez donc les villes de{" "}
                        <strong>l’île de France</strong>, <strong>Lyon</strong>,{" "}
                        <strong>Toulouse</strong> ou même{" "}
                        <strong>Montpellier</strong> pour un investissement sûr.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  itemscope
                  itemprop="mainEntity"
                  itemtype="https://schema.org/Question"
                >
                  <h3 itemprop="name">
                    A combien doit-on fixer le prix de location d’une place ou
                    d’un box&nbsp;?
                  </h3>
                  <div
                    itemscope
                    itemprop="acceptedAnswer"
                    itemtype="https://schema.org/Answer"
                  >
                    <div itemprop="text" class="faq-answer">
                      <p>
                        Pour déterminer le loyer de votre parking, étudiez les
                        alentours afin de proposer une offre concurrentielle.
                        Votre conseiller Cogedim saura vous guider dans cette
                        démarche.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  itemscope
                  itemprop="mainEntity"
                  itemtype="https://schema.org/Question"
                >
                  <h3 itemprop="name">
                    Il y a-t-il un contrat dans le cadre d’une location de
                    parking&nbsp;?
                  </h3>
                  <div
                    itemscope
                    itemprop="acceptedAnswer"
                    itemtype="https://schema.org/Answer"
                  >
                    <div itemprop="text" class="faq-answer">
                      <p>
                        Oui, il y a un contrat à signer entre le propriétaire du
                        parking et le locataire dans le cadre de sa location. Il
                        est important de bien vérifier chaque point pour éviter
                        tout malentendu.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="expand-button">
              <button
                type="button"
                onClick={() => setIsExpanded(!isExpanded())}
                aria-label="Découvrir pourquoi investir dans un parking neuf"
                data-test="btn-expand-seo"
              >
                <i aria-hidden="true" class="cog-icon">
                  <IconExpandMore />
                </i>
              </button>
            </div>
          </div>
          <p class="legals" data-test="legals">
            <sup>(1)</sup> Les estimations de rendement potentiel de 4 à 8% sont
            fournies à titre informatif uniquement et sans valeur contractuelle
            et ne constituent pas une garantie de performance future.
          </p>
        </div>
      </section>
    </>
  );
}
